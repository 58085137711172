@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');
@import "../node_modules/bootstrap/scss/bootstrap.scss";

/*Custom theme colors*/

$pl_red: #F00;
$pl_blue:#000064FF;
$pl_skyblue: rgba(0,
125,
255,
0.8);
$pl_orange: #fe7f01;
$pl_yellow: rgba(250,
225,
0,
0.5);
$pl_wood: #a86c00;
$pl_greensea: rgba(0,
175,
255,
0.8);

/*Navbar top/bottom*/

.bi-navbottom{
    font-size: 1.5em;
    color: $gray-600;
}

.bi-navtop{
    font-size: 1.5em;
    color: $gray-600;
}

.bi-txt {
    color: $gray-600;
    font-weight: normal;
    font-size: 14px;
}

.add-item {
    font-size: 1.75em;
    color: black;
}

.pl-blue {
    background-color: $pl_blue;
}

.pl-red {
    background-color: $pl_red;
}


/*
    Setup colors for journeystates
    
    Possible JourneyStates:
    
    Created = 10,
    Configured = 20,
    Started = 30,
    Hidden = 40,
    Stopped = 50,
    Finished = 60,
    Closed = 70
*/

$pl_journeyCreated: lightblue;
$pl_journeyConfigured: blue;
$pl_jounreyStarted: green;
$pl_journeyHidden: lightgray;
$pl_jounreyStopped: red;
$pl_journeyFinished: gray;
$pl_journeyClosed: black;
.jCreated {
    background-color: $pl_journeyCreated
}

.jConfigured {
    background-color: $pl_journeyConfigured
}

.jStarted {
    background-color: $pl_jounreyStarted
}

.btn-danger.jStopped {
    background-color: $pl_jounreyStopped
}

.btn-success.jStarted {
    background-color: $pl_jounreyStarted
}

.alert-secondary.jHidden {
    background-color: $pl_journeyHidden;
}

.jHidden {
    background-color: $pl_journeyHidden;
}

.jStopped {
    background-color: $pl_jounreyStopped;
}

.jFinished {
    background-color: $pl_journeyFinished;
}

.jClosed {
    background-color: $pl_journeyClosed;
}

.pl-bg-main {
    background: rgb(0, 125, 255);
    background: linear-gradient(180deg, rgba(0, 125, 255, 0.8) 0%, rgba(250, 225, 0, 0.5) 50%, rgba(0, 175, 255, 0.8) 100%);
    background-attachment: fixed;
    background-position: center;
}

.pl-bg-colored {
    /* The image used */
    /*background-image: url('assets/images/sea-with-ship-horizont.png');*/
    background-color: $pl_greensea;
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}